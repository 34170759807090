<template>
  <fw-layout
    full
    main-content-max-width-on-full="lg"
    :back-to-enable="false"
    :loading="loadingInitial"
    loading-icon="calendar"
    loading-title="Mapa de férias"
    right-sidebar-width="w-80 min-w-80"
    main-sidebar-width="w-64 min-w-64"
    :notfound="notFound"
  >
    <template v-if="map?.key && userMap?.key" #header-nav>
      <div>
        <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
          <fw-button-dropdown slot="trigger" aria-role="listitem" type="link-light">
            <span class="text-gray-500 font-medium text-xl mr-1">Mapa de férias</span>
            <span class="font-bold text-xl">{{ map.year ?? '' }}</span>
          </fw-button-dropdown>
          <b-dropdown-item
            v-for="userMapYear in allYears"
            :key="userMapYear.year"
            :label="`${userMapYear.year} ${statusLabels[userMapYear.status]?.['pt']}`"
            aria-role="listitem"
            class="flex items-center gap-2 justify-between"
            :class="`font-medium ${userMapYear.year == map.year ? 'text-primary' : 'text-gray-500'}`"
            @click.native="selectYear(userMapYear, userMapYear.year)"
          >
            <div class="font-bold">{{ userMapYear.year }}</div>
            <div v-if="userMapYear.version" class="text-xs opacity-80">v.{{ userMapYear.version }}</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <template #main-sidebar>
      <SidebarUserMaps ref="sidebarList" :year="map.year" @open="gotToUserMap">
        <fw-button
          v-if="validations?.can_create_version"
          type="light-primary"
          expanded
          @click.native="openCreateVersionModal"
          >Criar nova versão</fw-button
        >
      </SidebarUserMaps>
    </template>

    <template #main-content>
      <fw-panel-info v-if="userMap.blocked === true" type="orange" boxed custom-class="flex gap-3" class="mb-5">
        <div class="flex-1 flex flex-col gap-2">
          <div class="flex gap-3 items-center">
            <div class="relative">
              <fw-icon-settings class="w-6 h-6 flex-shrink-0" />
              <span v-if="userMap.blocked === true" class="-top-1 -right-1.5 absolute">
                <fw-dot color="orange" />
              </span>
            </div>
            <div class="flex-1 font-semibold text-base">Modo de gestão ativo</div>
          </div>
          <div class="text-xs">
            Este mapa foi colocado em modo de gestão por um gestor MyUC. Enquanto este modo não for fechado, não poderá
            realizar alterações.
          </div>
        </div>
      </fw-panel-info>

      <PanelCurrentUserMap
        v-if="isCurrentMap"
        :loading="loading"
        :calendar-attributes="calendarAttributes"
        :map="map"
        :user-map="userMap"
        :validations="validations"
        :year-activity="yearActivity"
        :map-user-days="mapUserDays"
        :current-working-version="currentWorkingVersion"
      />
      <PanelUserMap
        v-else
        :map="map"
        :loading="loading"
        :saving-data="savingData"
        :user-map="userMap"
        :validations="validations"
        :year-activity="yearActivity"
        :map-user-days="mapUserDays"
        :can-add-partial-day="canAddPartialDay"
        :current-version-motive-days-count="currentVersionMotiveDaysCount"
        @open-modal-add-days="openModalAddDays"
        @update-day="editDay"
        @delete-day="deleteDay"
        @accumulate-day="accumulateDay"
        @accumulate-remaining="confirmAccumulateRemaining"
      />
    </template>

    <template #right-sidebar>
      <ContentLoader v-if="loading" type="sidebar" />
      <div v-else-if="map?.key && userMap?.key" class="px-5 py-3 flex-1 flex flex-col gap-5">
        <div v-if="!isCurrentMap" class="border-b pb-2">
          <fw-label>Estado da versão do mapa</fw-label>
          <fw-tag
            size="lg"
            expanded
            :type="colors[userMap.status]"
            custom-class="px-3 flex items-center justify-center gap-2"
          >
            <span>{{ statusLabels[userMap.status]?.['pt'] }}</span>
          </fw-tag>
          <fw-panel-info v-if="userMap?.status === 'outdated'" size="xs" clean icon class="my-2">
            Quando uma nova versão é criada, as anteriores são automaticamente arquivadas.
          </fw-panel-info>
          <fw-panel-info v-if="userMap?.status === 'submitted'" size="xs" type="primary" clean icon class="my-2">
            Aguarda aprovação por parte do responsável pelo mapa.
          </fw-panel-info>
        </div>

        <div v-if="!isCurrentMap && !isServiceModeActive && (hasAvailableActions || isDraft)" class="border-b pb-5">
          <fw-label>Operações</fw-label>
          <div class="flex gap-2">
            <div v-if="isDraft || canSubmitVersion" class="flex-grow">
              <fw-button
                :type="canSubmitVersion ? 'primary' : 'border-light'"
                :disabled="!canSubmitVersion"
                expanded
                @click.native="submitMapUser"
                >Submeter</fw-button
              >
            </div>
            <div v-if="validations?.can_cancel" class="flex-grow">
              <fw-button :type="'border-light'" expanded @click.native="cancelMapUser">Cancelar</fw-button>
            </div>
          </div>
          <fw-panel-info v-if="!hasAvailableActions && isStarterMap && isDraft" size="xs" clean icon class="mt-2">
            Esta versão inicial do seu mapa apenas poderá ser submetida após indicar todos os dias de férias que tem
            disponíves.
          </fw-panel-info>
        </div>

        <BlockUserMapRules
          v-if="userMap.status !== 'canceled' && userMap?.version_motive != 'credit_days'"
          :periods="periods"
          :contingentes="userMap.contingentes"
          :year="map.year"
        />

        <div v-if="userMap.status !== 'canceled'" class="border-b pb-3">
          <div>
            <fw-button type="xlight" expanded icon="question" @click.native="isCreateTaskModalActive = true"
              >Precisa de ajuda?</fw-button
            >
          </div>
        </div>

        <div class="flex flex-col gap-2 border-b pb-3">
          <fw-label marginless>Informações de apoio</fw-label>
          <a
            v-if="[2023, 2024, 2025, 2026].includes(map.year)"
            href="https://www.uc.pt/drh/variosdocs/Despacho-34-2023.pdf"
            target="_blank"
            class="flex flex-col gap-0 text-sm"
          >
            <span class="text-primary font-medium">Despacho n.º 34/2023</span>
            <span class="!text-gray-500 text-xs">Ficheiro .pdf de acesso restrito</span>
          </a>
          <a
            href="https://www.uc.pt/sgrh/perguntas-frequentes/"
            target="_blank"
            class="text-primary font-medium text-sm"
          >
            Perguntas frequentes (FAQs)</a
          >
        </div>

        <BlockUserMapDetails v-if="userMap.status !== 'canceled'" :user-map="userMap" :map="map" :users="users" />
      </div>
    </template>

    <template #modals>
      <fw-modal
        :active.sync="modalCreateVersionOpen"
        size="sm"
        :title="'Criar versão'"
        description="Indique o motivo da criação da nova versão do mapa de férias."
        @close="closeCreateVersionModal"
      >
        <ModalCreateVersion
          :map-key="map.key"
          :map-year="map.year"
          :can-create-version="{
            vacations: canCreateVacationsVersion,
            credit_days: Boolean(canCreateCreditDaysVersion),
            accumulate_days: Boolean(canCreateCumulateDaysVersion)
          }"
          @close="closeCreateVersionModal"
          @created="createdVersion"
        />
      </fw-modal>
      <fw-modal
        :active.sync="modalAddDaysOpen"
        :can-cancel="true"
        size="full"
        width="54rem"
        boxed="sm"
        @close="closeModalAddDays"
      >
        <template #default>
          <ModalAddVacationsMapDays
            :unselectable-dates="unselectableDates"
            :excluded-weekdays="calendarExcludedWeekdays"
            :days-count="currentVersionMotiveDaysCount"
            :can-add-partial-day="canAddPartialDay"
            :is-creditdays="userMap?.version_motive == 'credit_days'"
            :events="events"
            :year="map.year"
            @close="closeModalAddDays"
            @add="addDays"
          />
        </template>
      </fw-modal>
      <WrapperCreateSupportTask :show="isCreateTaskModalActive" @close="closeCreateTaskModal" />
    </template>
  </fw-layout>
</template>

<script>
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import PanelUserMap from '@/components/panels/PanelUserMap'
import SidebarUserMaps from '@/components/sidebars/SidebarUserMaps'
import BlockUserMapDetails from '@/components/blocks/BlockUserMapDetails'
import BlockUserMapRules from '@/components/blocks/BlockUserMapRules'
import ModalAddVacationsMapDays from '@/components/modals/ModalAddVacationsMapDays'
import ModalCreateVersion from '@/components/modals/ModalCreateVersion'
import PanelCurrentUserMap from '@/components/panels/PanelCurrentUserMap'
import WrapperCreateSupportTask from '@/fw-modules/fw-core-vue/tasks/components/wrappers/WrapperCreateSupportTask'
import difference from 'lodash/difference'

import {
  MAP_USER_STATUS_COLORS,
  FULL_DAY_TYPES,
  PARTIAL_DAY_TYPES,
  MAP_USER_VERSION_REASONS,
  VACATIONS_DAY_TYPES,
  CREDIT_DAY_TYPES,
  MAP_USER_STATUS
} from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  components: {
    PanelUserMap,
    BlockUserMapRules,
    PanelCurrentUserMap,
    ModalAddVacationsMapDays,
    ModalCreateVersion,
    BlockUserMapDetails,
    SidebarUserMaps,
    ContentLoader,
    WrapperCreateSupportTask
  },

  data() {
    return {
      loadingInitial: true,
      colors: Object.freeze(MAP_USER_STATUS_COLORS),
      statusLabels: Object.freeze(MAP_USER_STATUS),
      vacationDayTypes: Object.freeze(VACATIONS_DAY_TYPES),
      creditDayTypes: Object.freeze(CREDIT_DAY_TYPES),
      fullDayTypes: Object.freeze(FULL_DAY_TYPES),
      partialDayTypes: Object.freeze(PARTIAL_DAY_TYPES),
      versionsMotives: Object.freeze(MAP_USER_VERSION_REASONS),

      loading: false,

      map: {},
      savingData: false,
      currentWorkingVersion: {},
      userMap: {},
      mapUserDays: [],
      uniqueMapUserDays: [], // To have the date only with the last sync_operation
      periods: [],
      requiredRules: [],

      users: {},
      validations: {
        can_edit_days: false,
        can_add_days: false,
        can_submit: false,
        can_cancel: true,
        can_create_version: false,
        can_add_message: false
      },

      allYears: [],

      isMapManager: false,
      isMapCreditdaysManager: false,
      isMapApprover: false,
      isMapCreditdaysApprover: false,
      isMapReporter: false,
      isMapGlobalReporter: false,

      modalCreateVersionOpen: false,
      modalAddDaysOpen: false,

      calendarDisableDates: [],
      calendarAllowedDates: [],
      invalidDates: [],
      holidays: [],
      calendarAttributes: [],
      latestApproved: {},
      latestVersions: {},

      isCreateTaskModalActive: false
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    language() {
      return this.$store.state.language
    },

    mapKey() {
      return this.$route.params.key
    },

    urlYear() {
      if (this.isCurrentMap) {
        return Number(this.mapKey.split('-')[1])
      }

      return null
    },

    allValidRules() {
      return this.requiredRules?.every(el => el.is_valid)
    },

    allValidContingentes() {
      return !this.userMap?.contingentes?.length || this.userMap?.contingentes?.every(el => el.is_valid)
    },

    hasChanges() {
      return this.mapUserDays?.some(el => el.is_from_previous_map == false)
    },

    hasPartialDays() {
      console.log('this.uniqueMapUserDays', this.uniqueMapUserDays)
      return this.uniqueMapUserDays?.some(el => PARTIAL_DAY_TYPES.includes(el.type))
    },

    canAddPartialDay() {
      // version_motive: 'credit_days','vacations','accumulate_days', 'sap_sync', 'starter_map'
      if (this.userMap.version_motive == 'credit_days') return true
      return (
        this.currentVersionMotiveDaysCount.remaining == -0.5 ||
        (this.currentVersionMotiveDaysCount.total % 1 != 0 &&
          this.currentVersionMotiveDaysCount.remaining <= 0.5 &&
          !this.hasPartialDays)
      )
    },

    canSubmitVersion() {
      if (this.userMap.version_motive == 'credit_days') {
        return (
          this.validations?.can_submit &&
          this.hasChanges &&
          this.userMap.days_count.credit_selected <= this.userMap.days_count.csv_credit
        )
      } else if ('accumulate_days' == this.userMap.version_motive) {
        return this.validations?.can_submit && this.hasChanges && this.allValidRules
      } else if (
        ['starter_map', 'vacations', 'sap_sync'].includes(this.userMap.version_motive) ||
        this.userMap?.is_sap_sync
      ) {
        console.log('canSubmitVersion', {
          can_submit: this.validations?.can_submit,
          allValidRules: this.allValidRules,
          allValidContingentes: this.allValidContingentes,
          remainingDays: this.userMap?.days_count?.remaining,
          hasChanges: this.hasChanges
        })

        if (this.userMap.version_motive == 'sap_sync' || this.userMap?.is_sap_sync) {
          return (
            this.validations?.can_submit &&
            this.allValidRules &&
            this.allValidContingentes &&
            this.currentVersionMotiveDaysCount?.remaining == 0
          )
        }
        return (
          this.validations?.can_submit &&
          this.hasChanges &&
          this.allValidRules &&
          this.allValidContingentes &&
          this.currentVersionMotiveDaysCount?.remaining == 0
        )
      }

      console.log('canSubmitVersion', {
        hasChanges: this.hasChanges
      })
      return this.validations?.can_submit && this.hasChanges
    },

    currentVersionMotiveDaysCount() {
      // csv_total:22
      // csv_compensated:0
      // csv_credit:0
      // credit_selected:0
      // credit_remaining:0
      // total:22
      // selected:4
      // accumulated:4
      // remaining:18
      if (!this.userMap?.key)
        return {
          total: 0,
          selected: 0,
          remaining: 0
        }

      if (this.userMap.version_motive == 'credit_days')
        return {
          total: this.userMap.days_count.csv_credit,
          selected: this.userMap.days_count.credit_selected,
          remaining: this.userMap.days_count.credit_remaining
        }

      return {
        total: this.userMap.days_count.total,
        selected: this.userMap.days_count.selected,
        remaining: this.userMap.days_count.remaining
      }
    },

    notFound() {
      return !this.loading && !this.loadingInitial && !this.userMap?.key
    },

    canCreateVacationsVersion() {
      return Boolean(
        this.validations?.can_create_version &&
          (this.userMap.year == new Date().getFullYear() || this.userMap.year == new Date().getFullYear() - 1)
      )
    },

    canCreateCreditDaysVersion() {
      return this.validations?.can_create_version && this.userMap.days_count?.csv_credit
    },

    canCreateCumulateDaysVersion() {
      return this.validations?.can_create_version && this.validations?.can_create_accumulate_days_version
    },

    hasAvailableActions() {
      return !this.isCurrentMap && (this.canSubmitVersion || this.validations?.can_cancel)
    },

    calendarExcludedWeekdays() {
      // Convert strings to new array with allowed weekdays
      let excludedWeekdays = [0, 6]
      if (this.userMap.allowed_weekdays?.length) {
        const allowedWeekdays = this.userMap.allowed_weekdays.map(day => {
          // Backend is returning day 1 as monday and we need to have as sunday
          return parseInt(day)
        })
        excludedWeekdays = difference([0, 1, 2, 3, 4, 5, 6], allowedWeekdays)
      }
      return excludedWeekdays
    },

    unselectableDates() {
      return [...new Set(this.invalidDates.concat(this.currentMapDays))]
    },

    events() {
      const events = {}

      for (const day of this.currentMapDays) {
        events[day] = {
          date: new Date(`${day}T00:00:00`),
          type: 'is-success'
        }
      }
      for (const day of this.invalidDates) {
        events[day] = {
          date: new Date(`${day}T00:00:00`),
          type: 'is-warning'
        }
      }

      return Object.values(events)
    },

    isCurrentMap() {
      return this.$route.params.key?.startsWith('current-')
    },

    isStarterMap() {
      return this.userMap?.version_motive == 'starter_map'
    },

    isDraft() {
      return this.userMap?.status == 'draft'
    },

    isServiceModeActive() {
      return this.userMap?.blocked === true
    },

    yearActivity() {
      let colors = {}
      let icons = {}
      let days = {}
      let uniqueMapUserDays = {}

      for (const day of this.holidays) {
        const dateFormatted = Dates.format(day.date, 'DD/MM/YYYY')
        days[dateFormatted] = day.title
        colors[dateFormatted] = 'bg-gray-300'
      }

      if (this.userMap?.birthday) {
        const dateFormatted = Dates.format(this.userMap?.birthday, 'DD/MM/YYYY')
        days[dateFormatted] = 'Aniversário'
        colors[dateFormatted] = 'bg-lime-500 bg-opacity-80 text-white'
        icons[dateFormatted] = 'start-smile-solid'
      }

      if (!this.mapUserDays?.length)
        return {
          data: days,
          colors,
          icons
        }

      if (this.isCurrentMap) {
        uniqueMapUserDays = this.uniqueMapUserDays
      } else {
        for (const day of this.mapUserDays) {
          if (!uniqueMapUserDays[day.date]) {
            uniqueMapUserDays[day.date] = day
          } else {
            delete uniqueMapUserDays[day.date]
            uniqueMapUserDays[day.date] = day
          }
        }
      }

      for (const day of this.uniqueMapUserDays) {
        if (day.sync_operation != 'del' && day.sync_operation != 'acc') {
          const dateFormatted = Dates.format(day.date, 'DD/MM/YYYY')
          if (CREDIT_DAY_TYPES.includes(day.type)) {
            days[dateFormatted] = FULL_DAY_TYPES.includes(day.type) ? 1 : 0.5
            colors[dateFormatted] = 'text-white'
            icons[dateFormatted] = 'record-circle'
          } else {
            days[dateFormatted] = FULL_DAY_TYPES.includes(day.type) ? 1 : 0.5
          }
        }
      }

      return {
        data: days,
        colors,
        icons
      }
    }
  },

  watch: {
    async mapKey(newVal) {
      if (newVal) {
        if (this.isCurrentMap) {
          const year = Number(newVal.split('-')[1])
          if (!year) return
          await this.getActiveUserMaps()
          await this.getMap()
        } else {
          this.getMap()
        }
      }
    }
  },

  async mounted() {
    if (this.isCurrentMap) {
      const year = Number(this.mapKey.split('-')[1])
      if (!year) return
      await this.getActiveUserMaps()
      await this.getMap()
    } else {
      await Promise.all([this.getMap(), this.getActiveUserMaps()])
    }
    this.loadingInitial = false
  },

  methods: {
    closeCreateTaskModal() {
      this.isCreateTaskModalActive = false
    },

    openCreateVersionModal() {
      if (!this.validations?.can_create_version) return

      this.modalCreateVersionOpen = true
    },

    closeCreateVersionModal() {
      this.modalCreateVersionOpen = false
    },

    closeModalAddDays() {
      this.modalAddDaysOpen = false
    },

    openModalAddDays() {
      console.log('open me')
      this.modalAddDaysOpen = true
    },

    async addDays(days) {
      console.log('----> addDays', days)
      if (!this.validations.can_add_days) {
        console.warn('can_add_days ', this.validations.can_add_days)
        return
      }

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.addUserMapDays(this.mapKey, days)
        console.log('addDay :>> ', response)
        this.setMapData(response)

        this.closeModalAddDays()

        this.$buefy.snackbar.open({
          message: `Os dias foram adicionados ao mapa.`,
          type: 'is-primary'
        })
      })
    },

    createdVersion() {
      this.modalCreateVersionOpen = false
      this.$refs.sidebarList.getUserMaps()
    },

    async getActiveUserMaps() {
      console.log('getActiveUserMaps')
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserMaps({
          latest_only: true,
          short_data: true
        })
        console.log('getActiveUserMaps :>> ', response)
        const allYears = []
        for (const userMap of response.user_maps) {
          allYears.push({ ...userMap, year: userMap.year })
        }

        this.latestApproved = response.latest_versions[this.urlYear]
        this.latestVersions = response.latest_versions

        this.allYears = allYears
        console.log('this.years', this.allYears)
      })
    },

    gotToUserMap(item, current = null) {
      console.log('gotToUserMap', item, current)
      if (current) {
        return this.$router.push({ name: 'map', params: { key: current } })
      } else {
        this.$router.push({ name: 'map', params: { key: item.key } })
      }
    },

    selectYear(userMap, year) {
      console.log('selectYear', { userMap, year })
      if (this.latestVersions[year]) this.gotToUserMap(userMap, 'current-' + year)
      else this.gotToUserMap(userMap)
    },

    setMapData(response) {
      this.currentWorkingVersion = response.working_version
      this.userMap = response.user_map
      this.map = response.map
      this.mapUserDays = response.map_user_days // !WARN! must be ordered by day and created date
      this.setUniqueDays()
      this.periods = response.periods
      this.validations = response.permissions
      this.isMapManager = response.roles?.is_map_manager
      this.isMapCreditdaysManager = response.roles?.is_map_creditdays_manager
      this.isMapApprover = response.roles?.is_map_approver
      this.isMapCreditdaysApprover = response.roles?.is_map_creditdays_approver
      this.isMapReporter = response.roles?.is_map_reporter
      this.isMapGlobalReporter = response.roles?.is_map_global_reporter
      this.users = { ...this.users, ...response.users }

      this.setDisabledDates()
      this.setSelectedDays()
    },

    async getMap() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        let mapKey = this.mapKey
        if (this.isCurrentMap) {
          mapKey = this.latestApproved.key
        }
        if (!mapKey) return
        const response = await this.api.getUserMap(mapKey)
        this.setMapData(response)
      })

      this.loading = false
    },

    setUniqueDays() {
      const uniqueMapUserDays = {}
      for (const day of this.mapUserDays) {
        if (!uniqueMapUserDays[day.date]) {
          uniqueMapUserDays[day.date] = day
        } else {
          delete uniqueMapUserDays[day.date]
          uniqueMapUserDays[day.date] = day
        }
      }

      this.uniqueMapUserDays = Object.values(uniqueMapUserDays)
    },

    setSelectedDays() {
      console.log('setSelectedDays')
      // this.creditDays = []
      // this.partialCreditDays = []
      // this.notEditableSameYearDays = []
      // this.partialNotEditableSameYearDays = []
      // this.fullDays = []
      // this.partialDays = []
      // this.accumulatedDays = []
      // this.accumulatedPartialDays = []
      this.currentMapDays = []

      for (let index = 0; index < this.uniqueMapUserDays.length; index++) {
        const day = this.uniqueMapUserDays[index]

        if (day.type.startsWith('creditday')) {
          if (day.sync_operation != 'del') {
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: day.type == 'creditday' ? 'Dia crédito' : `Crédito (${this.$t('types.' + day.type)})`,
                details:
                  day.type == 'creditday' ? 'Crédito' : `Dia de crédito parcial (${this.$t('types.' + day.type)})`,
                class: 'bg-primary text-white',
                type: day.type,
                entry: day
              },

              dates: new Date(`${day.date}T00:00:00`)
            })
          }
        } else if (day.type == 'allday') {
          if (!day.origin_allows_edit) {
            if (new Date(day.date).getFullYear() == this.map.year) {
              // this.notEditableSameYearDays.push(day.date)
              this.currentMapDays.push(day.date)
              this.calendarAttributes.push({
                key: day.key,
                customData: {
                  title: 'Não editável',
                  details: `Dia não editável (${day.sync_message})`,
                  class: 'bg-yellow-600 bg-opacity-10 text-yellow-600',
                  type: day.type,
                  entry: day
                },
                dates: new Date(`${day.date}T00:00:00`)
              })
            }
          } else if (day.sync_operation == 'add') {
            // this.fullDays.push(day.date)
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: 'Férias',
                details: 'Dia inteiro de férias',
                class: 'bg-primary text-white',
                type: day.type,
                entry: day
              },
              dates: new Date(`${day.date}T00:00:00`)
            })
          } else if (day.sync_operation == 'acc') {
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: 'Cumulado',
                details: 'Dia inteiro cumulado',
                class: 'bg-yellow-600 bg-opacity-10 text-yellow-700',
                type: day.type,
                entry: day
              },
              dates: new Date(`${day.date}T00:00:00`)
            })
          }
        } else {
          if (!day.origin_allows_edit) {
            if (new Date(day.date).getFullYear() == this.map.year) {
              // this.partialNotEditableSameYearDays.push(day.date)
              this.currentMapDays.push(day.date)
              this.calendarAttributes.push({
                key: day.key,
                customData: {
                  title: `Não editável (${this.$t('types.' + day.type)})`,
                  details: `Dia parcial não editável ${day.type} (${day.sync_message})`,
                  class: 'bg-yellow-600 bg-opacity-10 text-yellow-600',
                  type: day.type,
                  entry: day
                },
                dates: new Date(`${day.date}T00:00:00`)
              })
            }
          } else if (day.sync_operation == 'acc') {
            // this.accumulatedPartialDays.push(day.date)
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: `Cumulado (${this.$t('types.' + day.type)})`,
                details: `Dia cumulado parcial (${this.$t('types.' + day.type)})`,
                class: 'bg-yellow-600 bg-opacity-10 text-yellow-700',
                type: day.type,
                entry: day
              },
              dates: new Date(`${day.date}T00:00:00`)
            })
          } else {
            // this.partialDays.push(day.date)
            this.currentMapDays.push(day.date)
            this.calendarAttributes.push({
              key: day.key,
              customData: {
                title: `Férias (${this.$t('types.' + day.type)})`,
                details: `Dia de férias parcial (${this.$t('types.' + day.type)})`,
                class: 'bg-primary text-white',
                type: day.type,
                entry: day
              },
              dates: new Date(`${day.date}T00:00:00`)
            })
          }
        }
      }
    },

    setDisabledDates() {
      console.log('setDisabledDates')
      this.requiredRules = []

      let blocked = []
      let allowed = []
      let holidays = []
      this.periods.forEach(period => {
        let blockedPeriodsAttributes = []
        if (period.type == 'holiday') {
          if (!holidays.includes(period.start_at)) holidays.push(period.start_at)
          this.invalidDates.push(period.start_at)
          this.holidays.push({
            title: period.title,
            date: period.start_at
          })
          this.calendarAttributes.push({
            key: period.key,
            customData: {
              title: period.title,
              class: 'bg-gray-400 text-white',
              type: period.type,
              entry: period
            },
            dates: {
              start: new Date(`${period.start_at}T00:00:00`),
              end: new Date(`${period.end_at}T00:00:00`)
            }
          })
        }

        // Rules required
        else if (period.type == 'required') {
          this.requiredRules.push(period)
        }

        // Resolve restantes dias (permitidos e bloqueados)
        else {
          const periodDays = Dates.getDatesFromRange(period.start_at, period.end_at).map(date =>
            Dates.buildCore(date).format('YYYY-MM-DD')
          )

          periodDays.forEach(periodDay => {
            // console.log('periodDay', periodDay)
            if (period.type == 'allowed') {
              if (!allowed.includes(periodDay)) allowed.push(periodDay)
            } else if (period.type == 'blocked') {
              if (!blocked.includes(periodDay)) blocked.push(periodDay)
              if (!blockedPeriodsAttributes.includes(periodDay)) blockedPeriodsAttributes.push(periodDay)
            }
          })

          if (period.type == 'blocked' && blockedPeriodsAttributes.length) {
            this.calendarAttributes.push({
              key: period.key,
              customData: {
                title: period.title,
                class: 'bg-gray-400 text-white',
                type: period.type,
                entry: period
              },
              dates: blockedPeriodsAttributes
            })
          }
        }
      })

      // Regista restantes dias - para bloquear
      blocked.forEach(day => {
        this.invalidDates.push(day)
      })

      // Adiciona nota no dia de aniversário
      if (this.userMap?.birthday) {
        this.invalidDates.push(this.userMap?.birthday)
        this.calendarAttributes.push({
          key: 'birthday',
          customData: {
            title: 'Parabéns! Este dia de dispensa é seu.',
            class: 'bg-teal-600 bg-opacity-10 text-teal-600',
            type: 'birthday'
          },
          dates: new Date(`${this.userMap.birthday}T00:00:00`)
        })
      }

      // console.log('this.requiredRules', this.requiredRules)
      // console.log('this.invalidDates', this.invalidDates)
    },

    async editDay(dayData) {
      console.log('editDay', dayData)
      if (!this.validations.can_edit_days) {
        console.warn('can_edit_days ', this.validations.can_edit_days)
        return this.$buefy.snackbar.open({
          message: `O dia ${dayData.date} não pode ser editado.`,
          type: 'is-warning'
        })
      }

      this.savingData = true
      const formattedDate = Dates.formatDateToAPI(dayData.date)
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.updateUserMapDays(this.mapKey, [{ date: formattedDate, type: dayData.type }])
        console.log('editDay response :>> ', response)
        this.setMapData(response)
        this.$buefy.snackbar.open({
          message: `O registo de dia ${dayData.date} foi atualizado.`,
          type: 'is-primary'
        })
      })

      this.savingData = false
    },

    async accumulateDay(day) {
      console.log('accumulateDay', day)
      if (this.userMap.version_motive != 'accumulate_days') {
        console.error('Cant accumulateDay', day)
        return this.$buefy.snackbar.open({
          message: `O dia ${day.date} não pode ser cumulado.`,
          type: 'is-warning'
        })
      }

      if (day.sync_operation === 'acc') {
        await utils.tryAndCatch(this, async () => {
          const response = await this.api.addUserMapDays(this.mapKey, [{ date: day.date, type: day.type }], {
            acc_remaining: false
          })
          console.log('addDay :>> ', response)
          this.setMapData(response)
          this.$buefy.snackbar.open({
            message: `O ${day.date} dia foi adicionado ao mapa.`,
            type: 'is-primary'
          })
        })

        return
      }

      this.editDay({ date: day.date, type: day.type ?? 'allday' })
    },

    confirmAccumulateRemaining() {
      this.$buefy.dialog.confirm({
        type: 'is-dark',
        title: 'Cumular dias restantes',
        message: `Tem a certeza que pretende cumular ${this.currentVersionMotiveDaysCount.remaining} dias?`,
        onConfirm: async () => {
          await this.accumulateRemaining()
        },
        confirmText: 'Cumular',
        cancelText: 'Fechar'
      })
    },

    async accumulateRemaining() {
      if (this.userMap.version_motive != 'accumulate_days' || !this.validations.can_edit_days) {
        return this.$buefy.snackbar.open({
          message: `Não podem ser cumulados os dias restantes.`,
          type: 'is-warning'
        })
      }

      if (this.currentVersionMotiveDaysCount.remaining <= 0) {
        return this.$buefy.snackbar.open({
          message: `Não tem dias para cumular.`,
          type: 'is-warning'
        })
      }

      this.savingData = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.cumulateRemainingUserMapDays(this.mapKey, this.mapUserKey, this.userKey)
        console.log('cumulate all response :>> ', response)
        this.setMapData(response)
        this.$buefy.snackbar.open({
          message: `Dias cumulados com sucesso.`,
          type: 'is-primary'
        })
      })

      this.savingData = false
    },

    async deleteDay(date) {
      console.log('deleteDay', date)
      if (!this.validations.can_edit_days) {
        console.warn('can_edit_days ', this.validations.can_edit_days)
        return this.$buefy.snackbar.open({
          message: `O dia ${date} não pode ser removido.`,
          type: 'is-warning'
        })
      }

      this.savingData = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.deleteUserMapDays(this.mapKey, [Dates.formatDateToAPI(date)])
        console.log('deleteDay response :>> ', response)
        this.setMapData(response)
        this.$buefy.snackbar.open({
          message: `O dia ${date} foi removido da lista.`,
          type: 'is-light'
        })
      })

      this.savingData = false
    },

    async changeStatus(status) {
      console.log('changeStatus: ', status)
      return await utils.tryAndCatch(this, async () => {
        const response = await this.api.updateUserMapStatus(this.mapKey, status)
        console.log('updateUserMapStatus :>> ', response)
        this.userMap.status = response.user_map.status
        this.userMap.version = response.user_map.version
        this.userMap.canceled_at = response.user_map.canceled_at
        this.userMap.submitted_at = response.user_map.submitted_at

        this.validations = response.permissions
        this.isMapManager = response.roles?.is_map_manager
        this.isMapCreditdaysManager = response.roles?.is_map_creditdays_manager
        this.isMapApprover = response.roles?.is_map_approver
        this.isMapCreditdaysApprover = response.roles?.is_map_creditdays_approver
        this.isMapReporter = response.roles?.is_map_reporter
        this.isMapGlobalReporter = response.roles?.is_map_global_reporter
        this.$refs.sidebarList.getUserMaps()

        return response
      })
    },

    cancelMapUser() {
      console.log('----> cancelMapUser')
      if (!this.validations.can_cancel) {
        console.warn('can_cancel ', this.validations.can_cancel)
        return
      }

      this.$buefy.dialog.confirm({
        type: 'is-dark',
        title: 'Cancelar pedido de alterações',
        message: 'Tem a certeza que pretende cancelar esta versão do seu mapa de férias?',
        onConfirm: async () => {
          const response = await this.changeStatus('canceled')
          if (!response) return
          this.$buefy.snackbar.open({
            message: `A versão do seu mapa de férias foi cancelada.`,
            type: 'is-light'
          })
        },
        confirmText: 'Cancelar versão',
        cancelText: 'Fechar'
      })
    },

    submitMapUser() {
      console.log('----> submitMapUser')
      if (!this.canSubmitVersion) {
        console.warn('can_submit ', this.canSubmitVersion)
        return
      }

      this.$buefy.dialog.confirm({
        type: 'is-primary',
        title: 'Submeter mapa de férias',
        message: 'Tem a certeza que pretende submeter esta versão do seu mapa de férias?',
        onConfirm: async () => {
          const response = await this.changeStatus('submitted')
          if (!response) return
          this.$buefy.snackbar.open({
            message: `A versão do seu mapa de férias foi submetida para aprovação.`,
            type: 'is-primary',
            position: 'is-top-right'
          })
        },
        confirmText: 'Submeter',
        cancelText: 'Cancelar'
      })
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito Manhã",
        "creditday-afternoon": "Dia de crédito Tarde",
        "creditday-halfday": "Dia de crédito parcial",
        "holiday": "Feriado",
        "birthday": "Aniversário"
      }
    },
    "en": {
      "syncOperations": {
        "add": "Adicionado",
        "del": "Removido",
        "acc": "Cumulado"
      },
      "types": {
        "allday": "Dia inteiro",
        "morning": "Manhã",
        "afternoon": "Tarde",
        "halfday": "Dia parcial",
        "creditday": "Dia de crédito",
        "creditday-morning": "Dia de crédito manhã",
        "creditday-afternoon": "Dia de crédito tarde",
        "creditday-halfday": "Dia de crédito parcial",
        "holiday": "Feriado",
        "birthday": "Aniversário"
      }
    }
  }
</i18n>
